@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  min-width: 100%;
}
body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0e0e23;
  overflow-x: hidden;
}
.font_one {
  font-family: "Inconsolata";
}
.cl {
  background: linear-gradient(270deg, rgba(117, 127, 154, 0.27) 0%, rgba(0, 0, 0, 0) 93.96%);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
