.hero_section {
    padding-top: 220px;
    padding-bottom: 340px;
    position: relative;
}
.hero_animation_container {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: -1;
    width: 54%;
}
.hero_animation_wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.left_text {
    text-align: left;
    max-width: 730px;
}
.hero_h {
    color: white;
    font-size: 84px;
    line-height: 92px;
    font-weight: 400;
    font-family: "Inconsolata";
    margin-bottom: 15px;
}
.hero_p_container {
    max-width: 600px;
    margin-bottom: 40px;
    text-align: left;
}
.hero_p {
    font-family: "Inconsolata";
    font-size: 24px;
    line-height: 38px;
    color: white;
}
.remove_element {
    opacity: 0;
    display: none;
}
.show_element {
    animation: showElement 1s forwards;
}
@keyframes showElement {
    0% {
        opacity: 0;
        transform: translateX(-15%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}
@media screen and (max-width: 991px) {
    .hero_animation_container {
        position: static;
        width: 100%;
        max-width: 600px;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;

    }
    .hero_section {
        padding-top: 50px;
        padding-bottom: 0;
    }
}
@media screen and (max-width: 767px) {
    .left_text {
        text-align: center;
    }
    .hero_h {
        font-size: 50px;
        line-height: 62px;
    }
    .hero_p_container {
        text-align: center;
    }
    .hero_p {
        font-size: 18px;
        line-height: 28px;
    }
}