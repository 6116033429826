.socials_cta {
    padding: 115px 95px 170px;
    border-radius: 40px;
    background-image: linear-gradient(180deg, #11224e, #0e0e23);
}
.socials_heading_box {
    max-width: 610px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
    text-align: center;
}
.socials_buttons_grid {
    display: grid;
    margin-top: 40px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.social_cta_button {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.socials_animations {
    animation: socialsAnimation 1s forwards;
}
@keyframes socialsAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media screen and (max-width: 767px) {
    .socials_buttons_grid {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
    .socials_cta {
        padding: 80px 40px;
    }
}