.recommendation_section {
    margin-top: 100px;
}
.recommendation_container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    display: flex;
    margin-top: 50px;
    margin-bottom: 140px;
}
.recommendation_img {
    max-width: 50%;
    height: auto;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-radius: 30px;
}
.recommendation_text_div {
    opacity: 0;
    text-align: left;
}
.recommendation_animation {
    animation: recommendationAnimation 1s forwards;
}
@keyframes recommendationAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}